import { IBlock } from "../../../../framework/src/IBlock";
import { Message } from "../../../../framework/src/Message";
import { BlockComponent } from "../../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from "react-toastify";
import { getStorageData } from "../../../../framework/src/Utilities";
export const configJSON = require("../../../../framework/src/config.js");
export const configJSONApi = require("../config.js");
// Customizable Area End




export interface Props {
  // Customizable Area Start
  navigation: string;
  id: string;
  // Customizable Area End
}

// Customizable Area Start
// Customizable Area End

interface S {
  // Customizable Area Start
  file: File | null;
  progress: number;
  isUploading: boolean;
  uploadComplete: boolean;
  SuccessMsg:string;
  selecteLanguage:string,
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: number;
  // Customizable Area End
}

export default class CustomFormController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiCallIdReportUpload : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.onPressMenuItem = this.onPressMenuItem.bind(this);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      file: null,
      progress: 0,
      isUploading: false,
      uploadComplete: false,
      SuccessMsg:"",
      selecteLanguage:"en",


      
      // Customizable Area End
    };

    // Customizable Area Start
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

    async componentDidMount(): Promise<void> {
      super.componentDidMount()
      const language = await getStorageData("appLanguage") || "ar";
      this.setState({ selecteLanguage: language })
    }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (responseJson.status === 500) {
          toast.error("Something went wrong!");
        } 
        if( apiRequestCallId === this.apiCallIdReportUpload) {
          toast.success("SIMAH Report Uploaded");
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);
  };

  handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && file.type === "application/pdf") {
      this.setState({ file, isUploading: true, uploadComplete: false }, this.simulateUpload);
    }
  };

  handleFileRemove =() => {
    this.setState({ file: null, uploadComplete: false,progress:0 }, () => {
      toast.success("SIMAH Report removed successfully");
    });
  }


  handleSuccessfulUploadNavigation = (screenName: string) => {
    if (this.state.SuccessMsg === "SIMAH Report uploaded successfully") {
      this.onPressMenuItem(screenName);
    }
  };

  

  simulateUpload = () => {
    const interval = setInterval(() => {
      this.setState((prevState):any => {
        if (prevState.progress >= 100) {
          clearInterval(interval);
          return {
            progress: 100,
            isUploading: false,
            uploadComplete: true,
          };
        }
        return {
          progress: prevState.progress + 10,
        };
      });
    }, 300);
  };

  
   removeQuotes(str:string) {
    return str?.replace(/"/g, '');
}

   uploadSimahReport = async () => {
    const loginToken = await getStorageData("login_token");
  
    const formData = new FormData();
    if (this.state.file) {
      formData.append("user_application_document[document_file]", this.state.file, "[PROXY]");
    }
  
     const baseUrl = configJSON.baseURL;
      const response = await fetch(
        `${baseUrl+configJSONApi.apiEndpointSimahReport}`,

        {
          method: "PUT", 
          headers: {
            token: this.removeQuotes(loginToken),
          },
          body: formData,
          
        }
        
        
      );
      
      const data = await response.json();
      if (data && data.message === "SIMAH Report uploaded successfully") {
        this.setState({ SuccessMsg: data.message }, () => {
          this.handleSuccessfulUploadNavigation("KnowYourCustomerKycVerificationAccount");
        });
        toast.success(this.state.SuccessMsg)
      }
    return data;
 
  };
  
  // Customizable Area End
}
