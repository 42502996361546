import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React from "react";
import * as Yup from "yup";
import { getStorageData , setStorageData} from "framework/src/Utilities";
interface MetaData {
  data: {
    attributes: {
      page_name: string;
      title_h1:string;
      arabic_title_h1:string;
      meta_title: string;
      meta_description: string;
      arabic_meta_title: string;
      arabic_meta_description: string;
      keywords:string; 
    }
  }
}
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
  type?: string;
}
interface InvalidResponseType {
  errors: string;
};

interface ValidResponseType {
  data: {
      id: string;
      type: string;
      attributes: {
          id: number;
          page_name: string;
          page_url: string;
          meta_title: string;
          title_h1:string;
      arabic_title_h1:string;
          meta_description: string;
          arabic_meta_title: string;
          arabic_meta_description: string;
          keywords: string;
          created_at: string;  
          updated_at: string; 
      };
  };
  meta: {
      message: string;
      seo_meta_tags: {
          title: string;
          meta: Array<{
              name: string;
              content: string;
          }>;
          open_graph: {
              title: string;
              description: string;
          };
      };
  };
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  annualRent: string,
  moveInDate: any,
  duration: string,
  openIndex:number | null,
  dateInputRef:any,
  FaqData:{
    id: string,
    attributes: {
      answer: string,
      question: string,
      answer_ar:string,
      question_ar:string,
    }
  }[],
  whyChooseData:{
    id:number,
    title:string,
    description:string,
    title_ar: string;
    description_ar: string;
  },
  selecteLanguage:string,
  isLoader: boolean;
  metaData :ValidResponseType;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      annualRent: "",
      moveInDate: null as Date | null,
      duration: '12 Months', 
      openIndex: null,
      dateInputRef : React.createRef(),
      FaqData:[],
      whyChooseData:{
        id:0,
        title:"",
        description:"",
        title_ar: "",
        description_ar: "",
      },
      selecteLanguage:"en",
      isLoader: false,
      metaData: {
        data: {
            id: "",
            type: "",
            attributes: {
                id: 0,
                page_name: "",
                page_url: "",
                meta_title: "",
                meta_description: "",
                title_h1:"",
      arabic_title_h1:"",
                arabic_meta_title: "",
                arabic_meta_description: "",
                keywords: "",
                created_at: "",
                updated_at: "",
            },
        },
        meta: {
            message: "",
            seo_meta_tags: {
                title: "",
                meta: [],
                open_graph: {
                    title: "",
                    description: "",
                },
            },
        },
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      let apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.isValidResponse(responseJson)) {
        this.apiSucessCall(apiRequestCallId, responseJson);
      }
    }
    this.handleCalculateInvest(from, message);
    this.handleGetFaq(from, message);
    this.handleWhyChooseUs(from, message);
    this.handleSubmissionResponse(from, message)
    // Customizable Area End
  }

  // Customizable Area Start
  onPressMenuItem = (screenName:string) => {
    const navigateMsg = new Message(getName(MessageEnum.NavigationMessage));
    navigateMsg.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
    navigateMsg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(navigateMsg);

  };
  checkNavigation = (token:any) => {
    if (token !== null) {
      this.onPressMenuItem("KnowYourCustomerKycVerification");
    }
    else {
      setStorageData("isSubmit", true);
      this.onPressMenuItem("Signup");
    }
  }

  handleSubmissionResponse = (from: string, message: Message) => {
    if (this.submissionApiId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
      if (responseJson.message === "Landing page created successfully") {
        const token :any = localStorage.getItem('login_token');
        localStorage.setItem('application_id', responseJson?.landing_page?.application_id)
       this.checkNavigation(token)
      }
    }
  }

  calculateDataId: string = "";
  getFaqDataId: string = "";
  getWhyChooseDataId: string = "";
  submissionApiId: string = "";
  seoCallId: string = ""

  firstDivRef:any = React.createRef();

  async componentDidMount(): Promise<void> {
    super.componentDidMount()
    this.postSeo();
    this.getFaqData();
    this.getWhyChooseData();
    const language = await getStorageData("appLanguage") || "ar";
    this.setState({ selecteLanguage: language })
    localStorage.setItem("duration", "12");
    this.firstDivRef.current.scrollIntoView();
  }

  handleCalculateInvest = (from: string, message: Message): void => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

    if (this.calculateDataId !== apiRequestCallID) {
      return;
    }
  
    const loginToken = localStorage.getItem('login_token');
    const navigationPath = this.getNavigationPath(loginToken);

    if (responseJson?.landing_page?.application_id && !loginToken) {
      localStorage.setItem('application_id', responseJson?.landing_page?.application_id)
    }
  
    if (responseJson) {
      this.navigateTo(navigationPath);
    }
  };
  
  getNavigationPath = (loginToken: string | null): string => {
    return loginToken ? "KnowYourCustomerKycVerification" : "Signup";
  };
  
  navigateTo = (navigationPath: string): void => {
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), navigationPath);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  };
  
  
  handleGetFaq = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.getFaqDataId === apiRequestCallID) {
      if(!responseJson.errors) {
        this.setState({FaqData:responseJson?.data})
      }
    }
  }

  handleCondition = (condition: any, truePart: any, falsePart: any) => {
    return condition ? truePart : falsePart
  }
  handleWhyChooseUs = (from: string, message: Message) => {
    const apiRequestCallID = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    if(this.getWhyChooseDataId === apiRequestCallID) {
      if(!responseJson.errors) {
        this.setState({whyChooseData:responseJson[0]})
      }
    }
  }
  goToHome() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationHomeScreenMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  CalculateSchema = Yup.object().shape({
    annualRent : Yup.string().required("Annual Rent is required"),
  })

  handleSubmitApplication=()=>{

    const token:any = localStorage.getItem('login_token');
    const loginToken = token ? token.slice(1, -1) : ""

    const header:any = {
      "Content-Type": configJSON.validationApiContentType,
    };

    if (loginToken) {
      header.token = loginToken;
    }

    const body = {
      "landing_page": {
        "request_type_button": "Submit Your Application"
      }
    }
    const calculateDataRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.submissionApiId = calculateDataRequest.messageId;

    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "bx_block_landingpage2/landing_pages"
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(calculateDataRequest.id, calculateDataRequest);
  }

  handleRentChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const numericValue = event.target.value.replace(
      /\D/g,
      ""
  );
    this.setState({ annualRent: numericValue });
    localStorage.setItem('annual_rent', this.state.annualRent)
  };

  handleDateChange = (event:React.ChangeEvent<HTMLInputElement>) => {
    const newMoveInDate = event.target.value;
    this.setState({ moveInDate: newMoveInDate });
    localStorage.setItem('move_in_date', newMoveInDate);
  };

  handleDateChange1 = (date : Date | null) => {
    if (date) {
    this.setState({ moveInDate: date });
      localStorage.setItem('move_in_date', date.toISOString());
    }
  }
  

  handleDurationChange = (event:any) => {
    const newDuration = event.target.value;
    this.setState({ duration: newDuration });
    localStorage.setItem('duration', newDuration.split(" ")[0]);
  };

  getFaqData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getFaqRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getFaqDataId = getFaqRequestMessage.messageId;
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getFaqDataEndPoint
    );
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    getFaqRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getFaqRequestMessage.id, getFaqRequestMessage);
  }

  getWhyChooseData = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const getWhyChooseRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getWhyChooseDataId = getWhyChooseRequestMessage.messageId;
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getWhyChooseDataEndPoint
    );
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    getWhyChooseRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(getWhyChooseRequestMessage.id, getWhyChooseRequestMessage);
  }

  handleSubmit = () => {
    const token:any = localStorage.getItem('login_token');
    const loginToken = token ? token.slice(1, -1) : ''
    const header:any = {
      "Content-Type": configJSON.validationApiContentType,
    };
    if (loginToken) {
      header.token = loginToken;
    }
    
    const body = {
        "landing_page": {
          "annual_rent": this.state.annualRent,
          "move_in_date": this.state.moveInDate,
          "duration": this.state.duration.split(" ")[0],
          "request_type_button" : "Calculate Now"
          }
      }
    const calculateDataRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.calculateDataId = calculateDataRequest.messageId;
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.calculateInvestEndPoint
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.exampleAPiMethod
    );
    calculateDataRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    runEngine.sendMessage(calculateDataRequest.id, calculateDataRequest);
  }

  handleFaqToggle=(index:number)=>{
    this.setState({ openIndex: this.state.openIndex === index ? null : index });
  }

  apiSucessCall = async (apiRequestCallId: string, responseJson: ValidResponseType) => {
    if (apiRequestCallId === this.seoCallId) {
      this.seoSucessCallBack(responseJson);
    }
  };

  seoSucessCallBack = (responseJson: ValidResponseType) => {
    this.setState({
      metaData: responseJson,
      isLoader: false
    })
  }

  apiCall = async (apiData: APIPayloadType) => {
    const { contentType, method, endPoint, body, type } = apiData;
    let token = await getStorageData("token")
    const header = {
      "Content-Type": contentType,
      token: token
    };
    const requestMessageLandingpage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessageLandingpage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    requestMessageLandingpage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessageLandingpage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body && type !== "formData"
      ? requestMessageLandingpage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessageLandingpage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessageLandingpage.id, requestMessageLandingpage);
    return requestMessageLandingpage.messageId;
  };

  isValidResponse = (responseJson: ValidResponseType) => {
    return (responseJson.data)
  };

  postSeo = async () => {
    
    this.seoCallId = await this.apiCall({
      method: configJSON.exampleAPiMethod,
      endPoint: configJSON.seoEndPoint,
      contentType: configJSON.exampleApiContentType,
    });
  };

  // Customizable Area End
}
