import React from "react";
// Customizable Area Start
import {
  Box, Typography, Button, Container, TextField, Modal ,IconButton,
  styled
} from "@mui/material";
import { Close } from '@mui/icons-material';
import { HeaderLogoo } from "./assets";
import { translate , getPoppinsFontByLanguage, getTextAlignByLanguage } from "../../../../packages/components/src/Translate";
import { Helmet } from 'react-helmet';
// Customizable Area End

import KnowYourCustomerKycVerificationController, {
  Props,
} from "./KnowYourCustomerKycVerificationController";
import { Formik } from "formik";
const initialValues = {
  firstNameDefault: "",
  lastNameDefault: "",
  middleNameDefault: "",
  emailStateDefault: "",
  nationalityDefault: "",
  dateOfBirth: "",
  phoneNumber: ""
}

export default class KnowYourCustomerKycVerification extends KnowYourCustomerKycVerificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { selectLanguage, metaData} = this.state

    return (
      <Container style={styles.container}>
        {selectLanguage === 'en' ? 
        <Helmet>
          <meta charSet="utf-8" />
        
          <meta name="description" content={metaData.data.attributes.meta_description} />
          <title>{metaData.data.attributes.title_h1}</title>
          
          <meta property="og:title" content={metaData.data.attributes.meta_title} />
          <meta property="og:description" content={metaData.data.attributes.meta_description} />
          <meta name="keywords" content={metaData.data.attributes.keywords} />
        </Helmet>
        : 
        <Helmet>
        <meta charSet="utf-8" />
        <title>{metaData.data.attributes.arabic_title_h1}</title>
        <meta name="description" content={metaData.data.attributes.arabic_meta_description} />
        <meta name="keywords" content={metaData.data.attributes.keywords} />
        <meta property="og:title" content={metaData.data.attributes.arabic_meta_title} />
        <meta property="og:description" content={metaData.data.attributes.arabic_meta_description} />
      </Helmet>
       }
        <Typography
          variant="h6"
          sx={{ display: 'flex',justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center', cursor: "pointer" }}
          data-test-id="home-btn-logo"
          onClick={() => this.onPressMenuItem("Home")}
        >
          <img src={HeaderLogoo.default} alt="Header Logo" />
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '80px', width: '100%' }}>

          <Box style={styles.idbox}>
            <Typography style={styles.idboxtext}>{translate('enterYourIdNum')}</Typography>
            <TextField type="text" id="standard-basic" placeholder={translate('nationalIdresNum')} color="secondary" variant="standard" style={styles.idinput} value={this.state.inputValue} onChange={this.inputChangeHandler} sx={{
              "& .MuiInput-underline:before": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInput-underline:after": {
                borderBottom: "2px solid #1D36BB",
              },
              "& .MuiInputBase-input::placeholder": {
                fontFamily: getPoppinsFontByLanguage(),
                marginBottom: "15px",
                textAlign:getTextAlignByLanguage()
              },
              "& .MuiInputBase-input, & .css-1x51dt5-MuiInputBase-input-MuiInput-input": {
                textAlign:getTextAlignByLanguage(),
              },
            }}
              onBlur={this.validateIdType}
            />
            {this.state.error && (<p style={{ height: "24px", marginTop: 0, marginBottom: "24px", textAlign:getTextAlignByLanguage(), color: 'red', fontFamily: getPoppinsFontByLanguage() }}> 
                {this.state.error}
           </p> )}
            <Typography sx={{ 
              fontFamily: getPoppinsFontByLanguage(),
              fontSize: '16px',
              fontWeight: 500,
              lineHeight: '22px',
              margin:'25px 0px',
              textAlign:getTextAlignByLanguage(),
            }}>
               {translate('kycTnC')}
            </Typography>
            <Button variant="contained" sx={styles.idbutton} disabled={!String(this.state.inputValue || '').trim()} role="button" onClick={this.handleOpenWithValidation}>
               {translate('nextButtonFG')}
            </Button>

            <BelowText > {translate('kycTextBelow')}  </BelowText>

          </Box>
        </Box>
        <div>
          <Modal
            open={this.state.open}
            onClose={this.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={modalstyle}>
              <IconButton sx={{ float: 'inline-end' }} onClick={this.handleClose}><Close /></IconButton><br /><br />
              <br/>
              <Typography style={styles.idboxtext} id="modal-modal-title" variant="h6" component="h2">
                {translate('verifyWithNafath')}
              </Typography>
              <Typography sx={{ textAlign: 'center', mt: 2, mb: 4, fontSize: "16px", fontWeight: 400, fontFamily: getPoppinsFontByLanguage() }} id="modal-modal-description">
                {translate('openYourNafath')}
              </Typography>
              <Box sx={{ textAlign: "center", mb: 3 }}>
                {this.state.otpValues.map((value: any, index: any) => (
                  <input
                    key={index}
                    type="text"
                    value={value}
                    style={{
                      width: "80px",
                      height: "80px",
                      margin: "auto",
                      borderRadius: "16px",
                      fontSize: "35px",
                      fontWeight: 700,
                      marginRight: "10px",
                      borderColor: "#1D36BB",
                      textAlign: "center",
                      fontFamily: getPoppinsFontByLanguage(),
                      outline: "none", 
                    }}
                    maxLength={1}
                    tabIndex={-1}
                    readOnly
                    onChange={(e) => this.handleInputChange(e, index)}
                  />
                ))}
              </Box>
            </Box>
          </Modal>
        </div>
      </Container>
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const BelowText = styled(Typography)({
  marginTop: '50px',
  fontFamily: getPoppinsFontByLanguage(),
  fontSize: '16px',
  lineHeight: '22px',
  textAlign: getTextAlignByLanguage(),
})

const modalstyle = {
  position: 'absolute',
  top: {
    xs: '35%',
    sm:'30%',
   md:'47%',
  },
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: '90%',
    sm: '80%',
    md: 500
  },
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '6px',
  boxShadow: 24,
  p: 2,
};


const styles = {
  container: {
    flex: 1,
    padding: 16,
    backgroundColor: "#ffffffff",
  },
  idbox: {
    width: '520px',
    height: '230px',
  },
  idboxtext: {
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 700,
    fontSize: '28px',
    display: 'flex',
    justifyContent: 'center',
    lineHeight: '29.33px',
  },
  idinput: {
    minWidth: '100%',
    height: '35px',
    marginTop: '80px',
    borderColor: '#1D36BB',
    fontFamily: getPoppinsFontByLanguage(),
  },
  idbutton: {
    minWidth: '100%',
    backgroundColor: '#1D36BB',
    borderRadius: '6px',
    padding: '10px 51px 10px 51px',
    fontWeight: 700,
    textTransform: "capitalize",
    fontSize: "20px",
    fontFamily: getPoppinsFontByLanguage(),
  },
};
// Customizable Area End
