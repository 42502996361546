// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
   Typography,
  ListItem, List,
  Box
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";

import ShariaComplianceController, {
    Props,
} from "./ShariaComplianceController";
import { getPoppinsFontByLanguage } from "../../../../packages/components/src/Translate";
import { Helmet } from "react-helmet";
const CustomTheme = createTheme(
  {palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: getPoppinsFontByLanguage(),
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
// Customizable Area End

export default class ShariaCompliance extends ShariaComplianceController {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
      const { selectLanguage, ShariaCompliancedata ,metaData} = this.state;
      let content;
    
      if (ShariaCompliancedata && ShariaCompliancedata.length > 0) {
        const data = ShariaCompliancedata[0]?.attributes;
            if (selectLanguage === "en") {
          content = (
            <>
              <Typography
                variant="h4"
                gutterBottom
                style={CustomStyles.HeadingStyle}
                dangerouslySetInnerHTML={{ __html: data?.title }}
              />
              <Typography variant="body1" paragraph>
                <div dangerouslySetInnerHTML={{ __html: data?.description }} />
              </Typography>
            </>
          );
        } else if (selectLanguage === "ar") {
          content = (
            <>
              <Typography
                variant="h4"
                gutterBottom
                style={CustomStyles.HeadingStyle}
                dangerouslySetInnerHTML={{ __html: data?.title_ar }}
              />
              <Typography variant="body1" paragraph>
                <Box
                  sx={{
                    "& ul": {
                      direction: "rtl",
                      paddingRight: "20px",
                    },
                    "& li": {
                      textAlign: "right",
                      listStylePosition: "inside",
                    },
                  }} dangerouslySetInnerHTML={{ __html: data?.description_ar }} />
              </Typography>
            </>
          );
        }
      } else {
        content = (
          <Typography
            variant="h6"
            align="center"
            sx={{
              color: "text.secondary",
              marginTop: "2rem",
            }}
          >
            No data available
          </Typography>
        );
      }
    
      return (
        <div id="main_block">
           {selectLanguage === "en" ?
           <Helmet>
              <meta charSet="utf-8" />
              <title>{metaData.data.attributes.title_h1}</title>
              <meta name="description" content={metaData.data.attributes.meta_description} />
            <meta property="og:title" content={metaData.data.attributes.meta_title} />
            <meta property="og:description" content={metaData.data.attributes.meta_description} />
              <meta name="keywords" content={metaData.data.attributes.keywords} />
            </Helmet>
            : 
            <Helmet>
            <meta charSet="utf-8" />
            <title>{metaData.data.attributes.arabic_title_h1}</title>
             <meta property="og:description" content={metaData.data.attributes.arabic_meta_description} />
             <meta name="description" content={metaData.data.attributes.arabic_meta_description} />
             <meta property="og:title" content={metaData.data.attributes.arabic_meta_title} />
            <meta name="keywords" content={metaData.data.attributes.keywords} />
            </Helmet>
             }
          <NavigationMenu navigation={this.props.navigation} id="" isFooter={true} >
            <ThemeProvider theme={CustomTheme}>
              <Container maxWidth="xl" sx={CustomStyles.mainDivStyle}>
                {content}
              </Container>
            </ThemeProvider>
          </NavigationMenu>
        </div>
      );
    }
    
    // Customizable Area End
}

// Customizable Area Start
const CustomStyles = {
  HeadingStyle: {
    fontSize: "28px",
    fontWeight: 700,
  },
  ParaStyle: {
    fontWeight: 400,
    fontSize: "16px",
    marginBottom: "20px",
  },
  SubHeadingStyle: {
    fontSize: "20px",
    fontWeight: 700,
    margin: "20px 0px",
  },
  DividerStyle: {
    marginTop: "30px",
    background: "#376FD0",
    height: "2px",
  },
  mainDivStyle: {
    marginTop: { xs: "145px", md: "174px" },
    padding: { xs: "", lg: "0px 100px", xl: "0px 220px" },
  },
}
// Customizable Area End
