// Customizable Area Start
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Container,
   Typography,

  List, ListItem,
  Box
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import { getPoppinsFontByLanguage } from "../../../../packages/components/src/Translate";
import { Helmet } from 'react-helmet';
import PrivacyPolicyController, {
    Props,
} from "./PrivacyPolicyController";

const webTheme = createTheme(
  {palette: {
      primary: {
        main: "#fff",
        contrastText: "#fff",
      },
      secondary: {
        main: "#376fd0",
        contrastText: "#fff",
      },
      error: {
        main: "#f44336",
        contrastText: "#fff",
      }
    },
    typography: {
      fontFamily: getPoppinsFontByLanguage(),
      h6: {
        fontWeight: 500,
      },
      subtitle1: {
        margin: "20px 0px",
      },
    },
  });
  
// Customizable Area End

export default class PrivacyPolicy extends PrivacyPolicyController {
    // Customizable Area Start
    // Customizable Area End
    
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    render() {
      
      const {selecteLanguage , metaData} = this.state
        return (
          <div id="main_block">
             {selecteLanguage === "en" ?
              <Helmet>
                <meta charSet="utf-8" />
                <title>{metaData.data.attributes.title_h1}</title>
                <meta property="og:description" content={metaData.data.attributes.meta_description} />
                <meta name="description" content={metaData.data.attributes.meta_description} />
                <meta name="keywords" content={metaData.data.attributes.keywords} />
                <meta property="og:title" content={metaData.data.attributes.meta_title} />
              </Helmet>
              :
              <Helmet>
              <meta charSet="utf-8" />
              <title>{metaData.data.attributes.arabic_title_h1}</title>
              <meta property="og:description" content={metaData.data.attributes.arabic_meta_description} />
              <meta name="description" content={metaData.data.attributes.arabic_meta_description} />
              <meta name="keywords" content={metaData.data.attributes.keywords} />
              <meta property="og:title" content={metaData.data.attributes.arabic_meta_title} />
            </Helmet>
             }
          <NavigationMenu navigation={this.props.navigation} id={""} isFooter={true} >
          <ThemeProvider theme={webTheme}>
          <Container maxWidth="xl" sx={WebStyles.mainDivStyle}>
              {selecteLanguage == "en" ?
                <>
                <Typography
                  variant="h4"
                  gutterBottom
                style={WebStyles.HeadingStyle}
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacyPolicyDatadata[0]?.attributes?.title,
                  }}
                />
  
                <Typography variant="body1" paragraph>
  
                  <div dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyDatadata[0]?.attributes?.description }} />
                </Typography>
  
                </>
                 :
                 <>
                 <Typography
                  variant="h4"
                  gutterBottom
                style={WebStyles.HeadingStyle}
                  dangerouslySetInnerHTML={{
                    __html: this.state.privacyPolicyDatadata[0]?.attributes?.title_ar,
                  }}
                />
  
                      <Typography variant="body1" paragraph>
                        <Box
                          sx={{
                            "& ul": {
                              direction: "rtl",
                              paddingRight: "20px",
                            },
                            "& li": {
                              textAlign: "right",
                              listStylePosition: "inside",
                            },
                          }}
                          dangerouslySetInnerHTML={{ __html: this.state.privacyPolicyDatadata[0]?.attributes?.description_ar }}
                        />
                      </Typography>
                 </>
                 }
  
              </Container>
            </ThemeProvider>
          </NavigationMenu>
        </div>
        );
    }
    // Customizable Area End
}

// Customizable Area Start
const WebStyles = {
  SubHeadingStyle: {
    fontWeight: 700,
    fontSize: "20px",
    margin: "20px 0px",
  },
  ParaStyle: {
    fontSize: "16px",
    marginBottom: "20px",
    fontWeight: 400,
  },
  HeadingStyle: {
    fontWeight: 700,
    fontSize: "28px",
  },
  DividerStyle: {
    background: "#376FD0",
    height: "2px",
    marginTop: "30px",
  },
  mainDivStyle: {
    marginTop: { xs: "145px", md: "174px" },
    padding: { xs: "", lg: "0px 100px", xl: "0px 220px" },
  },
}
// Customizable Area End
