// Customizable Area Start
import React from "react";
import {
  // Customizable Area Start
  Container,
  Typography,
  styled,
  Grid,
  Box,
  Button,
  LinearProgress
  // Customizable Area End
} from "@mui/material";

// Customizable Area Start
import CustomFormController, {
  Props,
} from "./components/CustomFormController.web";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CloseIcon from '@mui/icons-material/Close';


const images = require("./assets");
const text = require("./config");
import {getPoppinsFontByLanguage, translate, getFontSizeByLanguage } from "../../../../packages/components/src/Translate";
// Customizable Area End

export default class CustomForm extends CustomFormController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <MainDiv>
        <Typography
          variant="h6"
          sx={{ display: 'flex', justifyContent: { xs: 'center', sm: 'flex-start' }, alignItems: 'center', cursor: "pointer" }}
          data-test-id="home-button-logo"
          onClick={() => this.onPressMenuItem("Home")}
        >
          <img src={images.HeaderLogo.default} alt="Header Logo" />
        </Typography>

        <Grid container className="mainPageContent">
          <Grid item className="uploadSimah">
            <img className="simahLogo" src={images.SimahReportLogo.default} alt="Simah Report" />
        <Typography className="uploadSimahReportText"> {translate("uploadSimahReportTitle")}</Typography>
          </Grid>

          <Box sx={{ position: "relative", width: "520px","@media (max-width: 480px)": {
      width: "100%",
    }, }}>

            {this.state.isUploading && (
              <LinearProgress
                variant="determinate"
                value={this.state.progress}
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  height: "4px",
                  backgroundColor: "#E2E8F0",
                  borderRadius: "10px",
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#8833FF",
                  },
                }}
              />
            )}
        <Box className="uploadBtnBox" dir={this.state.selecteLanguage === "ar" ? "rtl" : "ltr"}>
  <div>
    {!this.state.uploadComplete ? (
      <label
        htmlFor="file-upload"
        style={{
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <img 
          src={images.UploadIcon.default} 
          style={{ 
            marginRight: this.state.selecteLanguage === "ar" ? "8px" : "0",
            marginLeft: this.state.selecteLanguage === "ar" ? "8px" : "0",
            paddingLeft: this.state.selecteLanguage === "ar" ? "8px" : "0px",
            paddingRight: this.state.selecteLanguage === "ar" ? "0" : "8px"
          }} 
        />
        <Box>
          <Typography variant="body1" component="span">
            {translate("uploadSimahInput")}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            PDF
          </Typography>
        </Box>
      </label>
    ) : (
                    <>
                      <Box style={{ display: "flex" }}>

                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                          }}
                        >
                          <Typography variant="body1" sx={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                            {this.state.file?.name}
                          </Typography>



                        </Box>
                        <Box style={{ display: "flex" }}>
                          <CloseIcon onClick={this.handleFileRemove} />
                        </Box>
                      </Box>
                    </>
    )}
  </div>
  <input
    id="file-upload"
    type="file"
    accept="application/pdf"
    style={{ display: "none" }}
    data-test-id="fileUploadd"
    onChange={this.handleFileChange}
  />
</Box>
          </Box>

          <Button type="button" data-test-id="nextBtn" onClick={this.uploadSimahReport} className={`${this.state.progress === 100 ? "nextBtn" : "disabledNextBtn"}`} disabled={!this.state.file}>
             {translate("uploadSimhaButton")}
          </Button>

        </Grid>
      </MainDiv>
      // Customizable Area End
    );
  }
}

const MainDiv = styled(Container)(({ theme }) => ({
  flex: 1,
  padding: 16,
  backgroundColor: "#ffffffff",
  width: "100%",
  maxWidth: "520px",
  margin: "0 auto", 
  

  "& .mainPageContent": {
    marginTop: "72px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "0 16px",
    [theme.breakpoints.down("sm")]: {
      marginTop: "48px",
    },
  },

  "& .uploadSimah": {
    textAlign:"center",
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "24px",
    },
  },

  "& .simahLogo": {
    marginBottom: "16px",
    width: "80%",
    maxWidth: "150px",
  },

  "& .uploadSimahReportText": {
    fontFamily: getPoppinsFontByLanguage(),
    fontWeight: 700,
    fontSize:getFontSizeByLanguage(),
    color: "#000000",
    [theme.breakpoints.down("sm")]: {
      fontSize: "18px",
    },
  },

  "& .uploadBtnBox": {
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #ccc",
    borderRadius: "8px",
    padding: "8px 0 8px 8px",
    width: "97%",
    marginBottom: "24px",
  },

"& .nextBtn, & .disabledNextBtn": {
  width: "100%", 
  maxWidth: "520px",
  backgroundColor: "#1D36BB",
  textTransform: "capitalize",
  borderRadius: "8px", 
  color: "white",
    fontSize:getFontSizeByLanguage(),
  fontWeight: 700,
  fontFamily: getPoppinsFontByLanguage(),
  padding: "12px",
  marginTop: "16px",
  "&:hover": {
    backgroundColor: "#1A2F99",
  },
  "@media (min-width: 600px)": {
    width: "520px", 
  },
},
"& .disabledNextBtn": {
  backgroundColor: "#F1F5F9",
  color: "#808080",
  fontFamily: getPoppinsFontByLanguage(),
  fontSize:getFontSizeByLanguage(),
},

}));


// Customizable Area End
